import React, { useEffect, useRef, useState } from "react"
import { graphql, PageProps, Link } from "gatsby"
import { ArrowRight } from "react-feather"
import ScrollIntoView from "react-scroll-into-view"
import Img from "gatsby-image"

import Layout from "../components/layout"
import { Button } from "../components/ui"
import Carousel from "../components/Carousel"
import ItemPortfolio from "../components/item-portfolio"
import ItemBlog from "../components/item-blog"
import { Form, Description as ContactDescription } from "../components/contact"
import { IndexPageQuery } from "./__generated__/IndexPageQuery"
import Popup from "../components/Popup"

export default ({ data, location }: PageProps<IndexPageQuery>) => {
    const siteData = data.site.siteMetadata

    const portfolioList = data.portfolio.edges.map((item, _) => (
        <ItemPortfolio
            data={item.node}
            key={`p-item-index-${item.node.id}`}
            even={(_ + 1) % 2 === 0}
        />
    ))

    const blogList = data.blog.edges.map(item => (
        <ItemBlog data={item.node} key={`b-item-index-${item.node.id}`} />
    ))

    return (
        <Layout
            front={true}
            seo={{
                title: "ホーム",
                description: siteData.description,
            }}
            navPlaceholder={false}
            location={location}
        >
            <Wall data={siteData} />
            {/* <Carousel /> */}
            {/* {siteData.about !== "" && <About data={siteData.about} />} */}
            {/* <div className="px-4 lg:px-0" id="portfolio">
                {portfolioList}
            </div> */}

            <Blog data={siteData.title}>{blogList}</Blog>

            <Popup />
            {/* <div id="contact">
                <Contact data={siteData.contact} />
            </div> */}
        </Layout>
    )
}

const Wall = ({ data }) => {
    return (
        <div className="mx-auto ">
            <div className="pt-20 pb-10 lg:pt-40 lg:pb-20 text-center ">
                <img src="/images/bgImage.png" className="w-full" />
            </div>
        </div>
    )
}

const About = ({ data }) => {
    return (
        <div className="boxed">
            <div className="px-4 py-20 text-center lg:py-40 lg:px-0">
                <h2 className="text-color-1 font-black text-5xl lg:text-6xl">
                    About
                </h2>
                <p className="mt-5 text-lg">{data}</p>
            </div>
        </div>
    )
}

const Blog = ({ children, data }) => {
    return (
        <div className="px-0 w-full">
            <div className="text-center">
                {/* <h2 className="text-color-1 font-black text-2xl lg:text-3xl">
                    お知らせ
                </h2> */}
                <Link to="/blog">
                    <h5 className="text-xl">News</h5>
                </Link>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-center">
                {children}
            </div>
        </div>
    )
}

const Contact = ({ data }) => {
    const hasContactForm = data.api_url
    return (
        <div className="container mx-auto">
            <div className="pt-20 pb-10 lg:pt-40 lg:pb-20 text-center">
                <h2 className="text-color-1 font-black text-5xl lg:text-6xl">
                    Contact
                </h2>
            </div>
            <div className="flex flex-wrap pb-40">
                {hasContactForm && (
                    <div className="w-full lg:w-1/2 px-4 lg:pl-2 lg:pr-6">
                        <Form
                            api={data.api_url}
                            data={data.site.siteMetadata.contact}
                        />
                    </div>
                )}
                <div
                    className={`w-full ${
                        hasContactForm ? "lg:w-1/2" : "lg:w-2/3 mx-auto"
                    } px-6 pt-8`}
                >
                    <ContactDescription data={data} />
                </div>
            </div>
        </div>
    )
}

export const query = graphql`
    query IndexPageQuery {
        site: site {
            siteMetadata {
                title
                description
                capitalizeTitleOnHome
                titleImage
                ogImage
                twoColumnWall
                introTag
                description
                about
                contact {
                    api_url
                    description
                    mail
                    phone
                    address
                }
                social {
                    name
                    url
                    icon
                }
            }
        }
        portfolio: allMdx(
            filter: { fields: { sourceName: { eq: "portfolio" } } }
            limit: 6
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        blog: allMdx(
            filter: { fields: { sourceName: { eq: "blog" } } }
            limit: 4
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        date(formatString: "YYYY年MM月DD日")
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
